import $ from 'jquery';
import 'bootstrap';

// フォームバリデーション

  $("form").change(function (e) {

    if ($('.error-box').children().hasClass('active')) {
      $('.error-box').addClass('active-box');
    } else {
      $('.error-box').removeClass('active-box');
    }

  var pwd = document.getElementById('password');
  var pwdCheck = document.getElementById('password-show');
  var pwd2 = document.getElementById('password-new');
  var pwdCheck2 = document.getElementById('password-show2');

  if($('input').hasClass('pass01')){
  pwdCheck.addEventListener('change', function () {
    if (pwdCheck.checked) {
      pwd.setAttribute('type', 'text');
    } else {
      pwd.setAttribute('type', 'password');
    }
  }, false);
  pwdCheck2.addEventListener('change', function () {
    if (pwdCheck2.checked) {
      pwd2.setAttribute('type', 'text');
    } else {
      pwd2.setAttribute('type', 'password');
    }
  }, false);
}else{
  pwdCheck2.addEventListener('change', function () {
    if (pwdCheck2.checked) {
      pwd2.setAttribute('type', 'text');
    } else {
      pwd2.setAttribute('type', 'password');
    }
  }, false);
}
});

  var $input = $('#password');
  $input.on('input', function (event) {
    var value = $input.val().length;
    if (1 <= value && 8 >= value) {
      $('.pass01').addClass('active');
    } else {
      $('.pass01').removeClass('active');
    }
  });

  var $input2 = $('#password-check');
  $input2.on('input', function (event) {
    var value1 = $input.val();
    var value2 = $input2.val();
    if (value1 == value2) {
      $('.pass02').removeClass('active');
    } else {
      $('.pass02').addClass('active');
    }
  });

  var $input9 = $('#password-new');
  $input9.on('input', function (event) {
    var value3 = $input9.val().length;
    if (1 <= value3 && 8 >= value3) {
      $('.pass03').addClass('active');
    } else {
      $('.pass03').removeClass('active');
    }
  });

  var $input10 = $('#password-check2');
  $input10.on('input', function (event) {
    var value4 = $input9.val();
    var value5 = $input10.val();
    if (value4 == value5) {
      $('.pass04').removeClass('active');
    } else {
      $('.pass04').addClass('active');
    }
  });

  var $input3 = $('#last-name');
  $input3.on('input', function (event) {
    if ($input3.val().match(/^[ぁ-んァ-ヶー一-龠 　\r\n\t]+$/)) {
      $('.name01').removeClass('active');
    } else {
      $('.name01').addClass('active');
    }
  });

  var $input4 = $('#first-name');
  $input4.on('input', function (event) {
    if ($input4.val().match(/^[ぁ-んァ-ヶー一-龠 　\r\n\t]+$/)) {
      $('.name02').removeClass('active2');
    } else {
      $('.name02').addClass('active2');
    }
  });

  var $input5 = $('#last-name-kana');
  $input5.on('input', function (event) {
    if ($input5.val().match(/^[ァ-ロワヲンー 　\r\n\t]*$/)) {
      $('.name03').removeClass('active');
    } else {
      $('.name03').addClass('active');
    }
  });

  var $input6 = $('#first-name-kana');
  $input6.on('input', function (event) {
    if ($input6.val().match(/^[ァ-ロワヲンー 　\r\n\t]*$/)) {
      $('.name04').removeClass('active2');
    } else {
      $('.name04').addClass('active2');
    }
  });

  $('#phone01').on("keypress", function (event) { return leaveOnlyNumber(event); });
  $('#phone02').on("keypress", function (event) { return leaveOnlyNumber(event); });
  $('#phone03').on("keypress", function (event) { return leaveOnlyNumber(event); });
  $('#post-number01').on("keypress", function (event) { return leaveOnlyNumber(event); });
  $('#post-number02').on("keypress", function (event) { return leaveOnlyNumber(event); });

  function leaveOnlyNumber(e) {
    var st = String.fromCharCode(e.which);
    if ("0123456789".indexOf(st, 0) < 0) { return false; }
    return true;
  }

  var $input7 = $('#post-number01');
  $input7.on('input', function (event) {
    var value3 = $input6.val().length;
    if (1 <= value3 && 2 >= value3) {
      $('.post01').addClass('active');
    } else {
      $('.post01').removeClass('active');
    }
  });

  var $input8 = $('#post-number02');
  $input8.on('input', function (event) {
    var value4 = $input7.val().length;
    if (1 <= value4 && 3 >= value4) {
      $('.post02').addClass('active');
    } else {
      $('.post02').removeClass('active');
    }
  });



  $('#cardnumber1').on("keypress", function (event) { console.log(event);return leaveOnlyNumber(event); });
  $('#cardnumber2').on("keypress", function (event) { return leaveOnlyNumber(event); });
  
  function leaveOnlyNumber(e) {
    var st = String.fromCharCode(e.which);
    if ("0123456789".indexOf(st, 0) < 0) { return false; }
    return true;
  }

  $('.toggle').on("click", function () {
  if($("#navbarsExampleDefault").hasClass('show')){
    $(".toggle").removeClass('js-open');
  }else{
    $(".toggle").addClass('js-open');
  }
});

$(window).on('load',function(){
  $('#rankup-modal').modal('show');
});